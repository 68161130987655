import logo from './logo.svg';
import okImage from './pay _ok.png';
import notOkImage from './pay_not_ok.png';
import './App.css';

function App() {
  const queryParametrs = new URLSearchParams(window.location.search)
  const authority = queryParametrs.get("Authority")
  const status = queryParametrs.get("Status")
  return (
    
    <div className="App">
      <header className="App-header">
        {status==="OK" ?
         <img src={okImage} className="App-logo" alt="logo" /> :
         <img src={notOkImage} className="App-logo" alt="logo" /> 
  }
 {status==="OK" ?
         <p className="App-text">پرداخت شما با موفقیت انجام شد</p> :
         <p className="App-text">پرداخت ناموفق بود</p>
  }
        <a 
          className="App-link"
          href={`madreseplus://madreseplus.io/?authority=${authority}&status=${status}`}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          بازگشت به مدرسه پلاس
        </a>
      </header>
    </div>
  );
}

export default App;
